.app-clinichistory {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    display: block;
    margin: 2% auto 2%;
    width: auto;
    min-width: calc(100%);
    max-width: calc(100%);
    min-height: 540px;
}

.app-clinichistory-form-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
}

.app-clinichistory-form-header-title {
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    color: var(--color-secondary);
}

.app-clinichistory-form-avatar {
    position: relative;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: calc(100%);
    height: calc(100%);
}

.app-clinichistory-form-avatar .avatar {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 50%;
    width: 192px;
    height: 192px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.app-clinichistory-form-avatar .avatar img {
    display: block;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
    border-radius: 50% !important
}

.app-button.default {
    display: block;
    min-width: auto;
    font-family: "Noto Sans bold";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
    letter-spacing: -0.33px;
    text-decoration-line: underline;
    color: var(--color-secondary-dark);
    background: none !important;
    box-shadow: none !important;
}

.app-clinichistory-table {
    display: block;
    margin-top: 50px;
}

.app-clinichistory-table-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: auto;
}

.app-clinichistory-table-header-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 200px;
}

.app-clinichistory-table-header-title,
.app-clinichistory-table-header-options {
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-primary);
}

.app-clinichistory-table-body {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    margin: 10px auto;
}

.app-clinichistory-table-body-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
}

.app-clinichistory-table-body-row .title {
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
}

.app-clinichistory-table-body-row .options {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 34px;
    letter-spacing: -0.33px;
    color: var(--color-gray-dark);
}

.app-clinichistory-table-body-row .title .button,
.app-clinichistory-table-body-row .options .button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background: none;
    border: hidden;
}

.app-clinichistory-table-body-row .options .icon {
    font-size: 16px;
    color: var(--color-gray-dark);
}

.app-clinichistory-table-body-box {
    width: 100%;
    height: 0px;
    min-height: 100px;
    max-height: 150px;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow-x: hidden;
    overflow-y: auto;
    transition: 0.3s;
    transition-delay: 0.25ms;
}

.app-clinichistory-table-body-box::-webkit-scrollbar {
    display: block;
    max-width: 0.5em;
    overflow: auto;
    max-height: 0.5em;
    padding: 10px;
}

.app-clinichistory-table-body-box::-webkit-scrollbar-thumb {
    display: block;
    max-width: 0.5em;
    overflow: auto;
    max-height: 1em;
    background: #00000025;
    border-radius: 5px;
}

.app-clinichistory-table-body-box .row {
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
    align-items: flex-start;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: var(--color-gray-dark);
}

.app-clinichistory-table-body-box .row:nth-child(1) {
    display: flex;
    flex-direction: row;
    padding: 5px 0px;
    align-items: flex-start;
    font-family: "Noto Sans Bold";
    font-weight: 700;
}

.app-clinichistory-table-body-box .row:nth-child(1) div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-clinichistory-table-body-box .row div:nth-child(1) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.app-clinichistory-table-body-box .row div:nth-child(1) .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 4px;
    border: 1.5px solid #606060;
    width: 19px;
    height: 19px;
    font-size: 19.5px;
    border-radius: 50%;
    box-shadow: none !important;
}

.app-clinichistory-table-body-box .row div:nth-child(1) .button.checked::before {
    content: "\f00c";
    font-family: "Font Awesome 6 Free";
    color: white;
    width: 19px;
    height: 19px;
    border: hidden;
    border-radius: 50%;
    background-color: var(--color-secondary);
    font-size: 19px;
}

.app-clinichistory-table-body-box .label {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    font-family: "Noto Sans Regular";
    font-style: "normal";
    font-size: 18px;
}

.app-clinichistory .app-clinichistory-photo {
    position: relative;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    vertical-align: middle;
    height: 100%;
    width: 100%;
}

.app-clinichistory .app-clinichistory-photo .avatar {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 50%;
    width: 192px !important;
    height: 192px !important;
    border: 1px solid var(--color-gray);
}

.app-clinichistory .app-clinichistory-photo .avatar img {
    display: block;
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
    border-radius: 50% !important
}

.app-clinichistory .app-clinichistory-photo .avatar button {
    position: absolute;
    right: 25%;
    bottom: 30%;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-white);
    border: 1px solid var(--color-gray);
}

.icon-button {
    position: relative;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 36px !important;
    height: 9px !important;
    border-radius: 50%;
    border: 1px solid #606060;
    background-color: var(--color-white);
    color: var(--color-gray-dark);
    padding: 10px;
}

.icon-button span {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 700;
}

.icon-button:hover {
    border: 1px solid #ffffff;
    background-color: var(--color-secondary);
    color: var(--color-white);
}

.app-clinichistory .form-label,
.app-clinichistory .form-check-label {
    display: flex;
    justify-content: flex-start;
}

.form-check-input:checked {
    background-color: var(--color-secondary);
    border-color: var(--color-secondary);
}

.text-green-link {
    white-space: nowrap !important;
    font-family: "Noto Sans Regular" !important;
    font-style: "normal" !important;
    font-size: 18px !important;
    font-weight: bold !important;
    color: var(--color-secondary-dark) !important;
    letter-spacing: 0.5px;
}

.app-clinichistory .btn {
    font-family: "Noto Sans Regular" !important;
    font-style: "normal" !important;
    font-size: 18px !important;
    min-width: 192px !important;
    min-height: 38px !important;
    box-shadow: 0px 4px 8px #00000025;
}

.app-clinichistory .btn.btn-sm {
    font-family: "Noto Sans Regular" !important;
    font-style: "normal" !important;
    font-size: 14px !important;
    min-width: 128px !important;
    min-height: 38px !important;
    box-shadow: 0px 4px 8px #00000025 !important;
}

.app-clinichistory .btn.btn-success {
    background: #64c736 !important;
    border: 1px solid #64c736 !important
}

.app-clinichistory .btn.btn-success:hover {
    opacity: 0.8;
}
