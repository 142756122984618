.app-forms-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 83px auto 2%;
  padding: 0% 2%;
}

.app-forms-header span {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: auto;
  text-align: center;
}

.app-forms-header span:last-child {
  display: block;
  margin: 2% auto;
  text-align: right;
  font-family: "Noto Sans Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  /* identical to box height, or 127% */
  letter-spacing: -0.18px;
  color: var(--color-gray-dark);
}

.app-forms-header .left span:last-child {
  text-align: left;
}

.app-forms-header .left span:nth-of-type(1) {
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: space-between;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.18px;
  color: var(--color-secondary);
}

.app-forms-header .right img {
  display: block;
  margin: auto;
  min-width: 262px;
  min-height: 68px;
}

.app-forms-header .right span:nth-of-type(1) {
  display: block;
  margin: auto;
  text-align: right;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.18px;
  color: var(--color-gray-dark);
}

.app-forms-container {
  position: relative;
  top: 0%;
  left: 0%;
  right: 0%;
  display: block;
  margin: 2% 4%;
  text-align: center;
}

.app-forms-container.boxshadow {
  margin: 2%;
  background-color: var(--color-white);
  padding: 10px 25px 30px;
  border: 1px solid #d9e1e7;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.app-forms-container.boxgray .app-forms-section-fields {
  margin: 0% 5%;
  background: #ebebeb;
  padding: 10px 25px 30px;
  border-radius: 10px;
}

.app-forms-container.box .app-forms-section-fields {
  margin: 0% 5%;
  padding: 10px 25px 30px;
  border-radius: 10px;
}

.app-forms-container.box-lg .app-forms-section-fields {
  margin: auto;
  padding: auto;
}

.app-forms-container.box .app-forms-section-title,
.app-forms-container.box-lg .app-forms-section-title,
.app-forms-container.boxgray .app-forms-section-title {
  font-family: "Noto Sans Italic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21.79px;
  color: var(--color-gray-dark);
  display: block;
  margin: 5px auto;
  text-align: center;
}

.app-forms-container.box .app-forms-section-title,
.app-forms-container.box-lg .app-forms-section-title,
.app-forms-container.boxgray .app-forms-section-title {
  font-family: "Noto Sans Italic";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21.79px;
  color: var(--color-gray-dark);
  display: block;
  margin: 5px auto;
  text-align: center;
}

.app-forms-container.box .form-group-radio .form-label,
.app-forms-container.box-lg .form-group-radio .form-label,
.app-forms-container.boxgray .form-group-radio .form-label {
  display: block;
  margin: 5px auto 10px;
  text-align: center;
  color: #242424;
  font-family: "Noto Sans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
}

.app-forms-section-actions {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: auto;
}

.app-forms-section-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 5px;
  margin: 15px auto;
  width: 100%;
  font-family: "Noto Sans Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 28px;
  letter-spacing: -0.18px;
  color: var(--color-primary);
}

.app-forms-section-title.app-collapse {
  cursor: pointer;
}

.app-forms-section-title.app-collapse::after {
  position: relative;
  content: "\f103";
  font-family: "Font Awesome 6 Free";
  font-size: 22px;
  color: var(--color-primary);
  cursor: pointer;
}

.app-forms-section-tools {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: space-between;
}

.app-forms-section-tools .tool {
  display: block;
  margin: auto;
  text-align: center;
  width: auto;
  height: auto;
}

.tool button {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  gap: 5px;
  margin: auto;
  background: none;
  border: hidden;
}

.tool span.icon {
  display: block;
  margin: auto;
  text-align: center;
  font-size: 26px;
  color: var(--color-gray-dark);
}

.tool span.label {
  display: block;
  margin: auto;
  text-align: center;
  font-family: "Noto Sans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.33;
  color: var(--color-gray-dark);
}

.app-forms-section-fields {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.app-forms-section-fields .form-group {
  margin: 8px 0px;
}

.app-forms-section-fields .form-control,
.app-forms-section-fields .form-select,
.app-forms-section-fields .form-check-input {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.app-forms-section-fields .form-label,
.app-forms-section-fields .form-check-label {
  display: block;
  margin: 5px 5px 0px 0px;
  text-align: left;
  font-family: "Noto Sans Bold";
  font-style: normal;
  font-weight: 700;
  color: var(--color-gray-dark);
  white-space: nowrap;
}

.app-forms-section-fields .form-group:has(textarea) .form-label {
  font-size: 22px;
  line-height: 30px;
  color: #242424;
}

.app-forms-section-fields .form-group-file {
  position: relative;
  top: 0%;
  left: 0%;
  right: 0%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.app-forms-section-fields .form-group-file-container {
  position: relative;
  top: 0%;
  right: 0%;
  bottom: 0%;
  left: 0%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 15px;
  width: 414px;
  height: 138px;
  max-width: 414px;
  max-height: 138px;
  border: 1px solid #d9e1e7;
  border-radius: 5px;
  background-color: var(--color-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.form-group-file-container .form-label {
  position: absolute;
  bottom: -25%;
  left: 0%;
}

.form-group-file-container button {
  position: absolute;
  bottom: 0%;
  right: 0%;
  display: block;
  margin: auto;
  text-align: center;
  padding: 10px 5px;
  border: hidden;
  background: none;
  font-family: "Noto Sans Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-gray-dark);
  opacity: 0.5;
  background: none;
}

.form-group-file-container button::after {
  content: "\f03e";
  margin: 0px 5px;
  font-family: "Font Awesome 6 Free";
  opacity: 0.8;
  font-size: 18px;
}

.form-group-file-container img {
  position: absolute;
  top: 0%;
  right: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: flex-start;
  padding: 5px;
  border-radius: 8px;
}

.form-group-radio {
  display: block;
  margin: 5px auto;
  text-align: center;
}

.form-group-radio .form-label {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.form-group-radio .form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: auto;
}

.form-paragraph {
  display: block;
  margin: auto;
  text-align: justify;
  font-family: "Noto Sans Regular";
  font-style: normal;
  font-size: 18px;
  line-height: 28px;
  color: #242424;
}

.form-paragraph input {
  border: 1px solid var(--color-gray);
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 300px !important;
  height: 36px !important;
}

.form-paragraph input::placeholder {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-size: 16px;
  color: var(--color-gray-dark);
}

.form-paragraph input:focus-visible {
  outline: #ced4da;
}

.form-paragraph input:disabled {
  background: #e9ecef;
}

.form-signature {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  width: auto;
  height: 38px;
  margin: 10px auto !important;
}

.form-signature label {
  font-family: "Noto Sans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  border-top: 2px solid #242424;
  margin: 14px 0px 14px;
  padding: 0px 50px;
}

.app-forms-button-container {
  position: relative;
  right: 0%;
  bottom: 0%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.app-forms-button {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  width: 250px;
  height: 40px;
  font-family: "Noto Sans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 34px;
  letter-spacing: -0.33px;
  border: 1px solid #64c736;
  border-radius: 5px;
  color: var(--color-white);
  background-color: var(--color-secondary);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.app-forms-button-outline {
  display: block;
  margin: auto;
  text-align: center;
  width: 250px;
  height: 40px;
  font-family: "Noto Sans Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  letter-spacing: -0.33px;
  border: 1px solid #39711f;
  border-radius: 5px;
  color: var(--color-secondary-dark);
  background-color: var(--color-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* audiograma */

.audiogram {
  position: relative;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
}

.audiogram .top {
  position: absolute;
  right: 0%;
  left: 0%;
  bottom: -4%;
  font-family: "Noto Sans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  color: var(--color-gray-dark) !important;
}

.audiogram .bottom {
  position: absolute;
  left: 1%;
  top: 15%;
  font-family: "Noto Sans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: var(--color-secondary-dark);
}

.form-radiorow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.form-radiocol {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  margin: auto;
}

.form-radiorow .form-label,
.form-radiocol .form-label {
  font-size: 16.5px;
  color: #242424;
}

.form-radiorow .options {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
}

.form-radiocol .options {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  align-items: flex-start;
  gap: 10px;
}

.form-radiocol .option {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: calc(100%);
}

.form-radiorow .option {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
}

.form-radiocol .option label,
.form-radiorow .option label {
  font-family: "Noto Sans Bold";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;
  color: var(--color-gray-dark);
}
