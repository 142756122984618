.app-settings {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    display: block;
    margin: 20px auto 20px;
    text-align: center;
    min-height: 600px;
}

.app-settings-title {
    display: block;
    margin: 28px auto;
    text-align: left;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
}
