.app-header-container {
    position: relative;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 4px auto;
}

.app-header-container .divider {
    border-right: 2px solid #39711f;
    min-height: 56px;
    border-radius: 4px;
}

.app-icon {
    width: 64px;
    height: 55px;
    margin: auto;
}

.app-icon button {
    display: block;
    margin: auto 5px;
    text-align: center;
    background: none;
    border: 0;
    width: 55px;
    height: 55px;
    border-radius: 5px;
}

.app-icon.active button {
    background: #39711f;
}

.app-icon img {
    display: block;
    text-align: center;
    margin: auto;
    background: unset;
    border: unset;
    width: 36px;
}

.app-header-search {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    width: fit-content;
    justify-content: space-around;
}

.app-header-search button {
    background: none;
    border: 0;
    margin: auto 26px;
}

.app-header-search input {
    background: none;
    border: 0;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-white);
    width: 210px;
}

.app-header-search input:focus-visible {
    outline: none;
    border-radius: 3px;
}

.app-header-search input::placeholder {
    color: var(--color-white);
}

.app-header-logo {
    position: relative;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: auto;
}

.app-header-menu {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 4px;
    display: block;
    width: auto;
    max-width: 252px;
}

.app-header-menu .truncate-text {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    max-width: 192px;
}

.app-header-menu:has(ul:hover) {
    background: #39711f;
}

.app-header-menu span {
    display: flex;
    text-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    padding-right: 35px;
    max-width: 240px;
}

.app-header-menu ul {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    text-align: left;
    padding: 5% 0% 5% 5%;
    min-width: 252px !important;
    max-width: 252px !important;
    height: 55px;
    font-family: "Century Gothic";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-white);
}

.app-header-menu ul>img {
    position: absolute;
    right: 0%;
    top: 0%;
    display: block;
    text-align: center;
    margin: 10% 5%;
}

.app-header-menu ul li {
    position: relative;
    left: -5%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: auto;
    padding: 5%;
    width: 252px;
    min-height: 55px;
    background: #39711f;
}

.app-header-menu ul:hover li:nth-of-type(1) {
    margin: 7% auto 0%;
}

.app-header-menu ul:hover li {
    display: flex;
}

.app-header-menu ul li .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: super;
    width: auto;
}

.app-header-menu ul li .icon img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 16px !important;
    height: 16px !important;
}

.app-header-menu ul li .divider {
    position: absolute;
    bottom: 0;
    height: 0%;
    display: block;
    text-align: center;
    width: 201px;
    border-bottom: 2px solid rgba(61, 87, 138, 0.5);
}

.app-header-menu ul li .label {
    display: flex;
    justify-content: flex-start;
    width: 60%;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-white);
    text-wrap: nowrap;
}


.app-change-password-modal .modal-header {
    margin: 0px !important;
    background: #64c736 !important;
    color: var(--color-white) !important;
}

.app-change-password-modal .modal-header>button.btn-close {
    color: var(--color-white) !important;
    border: 1px solid #ffffff !important;
}

.app-change-password-modal .modal-body .btn {
    min-width: 128px !important;
    min-height: 36px !important;
}

.app-change-password-modal .modal-body .btn.btn-success {
    background: #64c736 !important;
    color: var(--color-white) !important;
    border: 1px solid #64c736 !important;
    box-shadow: 0px 4px 8px #00000050 !important;
    min-width: 126px;
}

.app-change-password-modal .modal-body .btn.btn-outline-danger {
    color: var(--color-danger) !important;
    border: 1px solid var(--color-danger) !important;
    box-shadow: 0px 4px 8px #00000050 !important;
}

.app-change-password-modal .modal-body .btn.btn-outline-danger:hover {
    background: var(--color-danger) !important;
    color: var(--color-white) !important;
}
