.container {
    width: auto;
    height: auto;
    background-image: url("../../../assets/images/home/background.svg");
    background-size: contain;
}

.form {
    position: absolute;
    min-width: 450px;
    max-width: 520px !important;
    top: 25%;
    bottom: auto;
    left: 0%;
    right: 0%;
    margin: auto;
    padding: 50px;
    border: 1px solid #d9d9d0;
    border-radius: 10px;
    background-color: var(--color-white);
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.form .buttons {
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    align-content: space-evenly !important;
    justify-content: space-evenly !important;
    gap: 1 !important;
}

.form .buttons .btn {
    display: block;
    margin: auto;
    text-align: center;
    width: auto;
    min-width: 190px;
    min-height: 48px;
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -0.05px;
    white-space: nowrap;
    color: var(--color-white);
    border: hidden;
    background: #25cec5;
    box-shadow: -4px -4px 4px #ffffff, 2px 2px 4px #606060;
    border-radius: 5px;
}

.footer {
    position: absolute;
    bottom: 5%;
    left: 0%;
    right: 0%;
    display: block;
    margin: auto;
    text-align: center;
    font-family: "Century Gothic";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
}
