.app-table-pagination {
    position: relative;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 485px;
    height: 48px;
    background: #ffffff25;
    border: 1px solid #d9e1e7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
    margin-top: -50px;
}

.app-table-pagination span {
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: var(--color-gray-dark);
}

.app-table-pagination div:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    width: 323px;
    height: 48px;
    background: var(--color-japanese-laurel);
    border: 1px solid #d9e1e7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
}

.app-table-pagination div div:nth-of-type(2) {
    display: flex;
    width: 143px;
    height: 40px;
    background: #ffffff25;
    border: unset;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
    border-radius: 15px;
}

.app-table-pagination button {
    background: none;
    border: none;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: var(--color-white);
}

.app-table-pagination button:disabled {
    color: var(--color-white)50;
}

.app-table-button {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: -50px;
}
