.app-navbar {
    display: block;
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 100%;
    height: auto;
    background: #00000040;
    transition: 0.3s;
}

.app-navbar-menu {
    position: fixed;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    width: 0px;
    height: auto;
    background-color: var(--color-white);
    transition: 0.3s;
}

.app-navbar-menu.active {
    width: 368px;
}

.app-navbar-menu-shadow {
    position: absolute;
    top: 65px;
    left: 0%;
    right: 0%;
    bottom: 0%;
    background: rgba(100, 199, 54, 0.15);
    box-shadow: -4px 4px 10px rgba(0, 0, 0, 0.25);
}

.app-navbar-menu ul {
    display: block;
    list-style: none;
    padding: 0%;
}

.app-navbar-menu ul>span {
    display: block;
    text-align: left;
    margin: auto 10%;
    font-family: "Century Gothic";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: rgba(96, 96, 96, 0.5);
}

.app-navbar-menu ul li {
    display: block;
    margin: auto;
    text-align: center;
    list-style: none;
    height: 52px;
    width: 95%;
    border-bottom: 3px solid rgba(96, 96, 96, 0.1);
}

.app-navbar-menu ul li {
    display: grid;
    grid-template-columns: 2fr 10fr;
    margin: 5px auto 0px;
    justify-content: space-around;
}

.app-navbar-menu a.active li {
    background: #39711f;
    border-radius: 5px;
}

.app-navbar-menu ul a.active span {
    color: #ffff;
}

.app-navbar-menu a.active img {
    filter: brightness(10);
}

.app-navbar-menu li span.icon {
    display: block;
    text-align: center;
    margin: auto;
    width: 32px !important;
    height: 32px !important;
}

.app-navbar-menu li span.icon img {
    display: block;
    margin: auto;
    width: 32px !important;
    height: 32px !important;
}

.app-navbar-menu li .label {
    display: block;
    text-align: left;
    margin: auto 2%;
    width: 90%;
    height: auto;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    /* identical to box height, or 156% */
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
    white-space: nowrap;
}

