.app-profiles {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    display: block;
    margin: 20px auto 20px;
    text-align: center;
    min-height: 600px;
}

.app-permissions-container {
    display: block;
    margin: auto;
    padding: 10px;
    width: auto;
    height: auto;
    min-height: 300px;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    background-color: var(--color-white);
    box-shadow: inset 0px 4px 4px var(--color-gray);
}

.app-permissions-options {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.app-permissions-option {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.app-permissions-option button {
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    white-space: nowrap;
    color: var(--color-gray-dark);
    padding: 0px 10px;
    border: 1px solid #00000075;
    border-radius: 4px;
    box-shadow: inset 0.5px 0.5px 2px rgba(0, 0, 0, 0.5), 2px 2px 4px rgba(0, 0, 0, 0.75);
}

.app-permissions-option button.selected {
    white-space: nowrap;
    border: 1px solid #ffffff;
    background: #25cec5;
    color: var(--color-white);
    border-radius: 4px;
    box-shadow: inset 0.5px 0.5px 2px rgba(0, 0, 0, 0.5), 2px 2px 4px rgba(0, 0, 0, 0.75);
}

.app-permissions-table {
    display: block;
    margin: 10px auto;
    padding: 10px;
    border: 1px solid var(--color-gray);
    border-radius: 4px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    min-height: 300px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}
