.app-home {
    display: block;
    margin: auto;
    padding-top: 100px;
}

.app-home-title {
    display: block;
    margin: 28px auto;
    text-align: left;
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
}
