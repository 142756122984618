.app-form-search {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-end;
}

.app-form-search input {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    min-width: 450px;
    min-height: 53px;
}

.app-form-search .form-control::after {
    position: absolute !important;
    right: 0% !important;
    content: "\f002" !important;
    font-family: "Font Awesome 6 Free" !important;
    background: #000000 !important;
    width: 20px;
    height: 20px;
    color: blue;
}

.table thead {
    white-space: nowrap;
}

.menu-button-detalle {
    position: absolute;
    right: -1%;
    top: -5%;
    padding: 0px 5px;
    display: none;
    list-style: none;
    width: 259px;
    min-height: 98px;
    background-color: var(--color-secondary);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px 0px 15px 15px;
    z-index: 1;
}

.menu-button-detalle li {
    display: block;
    margin: auto;
    text-align: left;
    padding: 10px;
    width: 100%;
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: var(--color-white);
    border-bottom: 2px solid rgba(57, 113, 31, 0.5);
}

.menu-button-detalle li:last-child {
    border-bottom: hidden;
}

button.detalle {
    position: relative;
    left: 0%;
    right: 0%;
    bottom: 0%;
    top: 0%;
}

button.detalle:hover>ul.menu-button-detalle {
    display: block;
}


/* boxes */

.app-protocols-form {
    display: flex;
    flex-direction: row;
    padding: 10px 10px 20px;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    background: #ffffff25;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.app-protocols-form-container {
    display: block;
    padding: 5px;
    min-height: 300px;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    background-color: var(--color-white);
    box-shadow: inset 0px 4px 5px rgba(0, 0, 0, 0.25);
}

.app-protocols-form-container:nth-of-type(2) {
    overflow: hidden;
}

.app-protocols-form-container input[type="search"]:focus,
.app-protocols-form-container input[type="search"]:focus-visible {
    outline: #606060;
}


/* Actions */

.app-protocols-actions {
    display: flex;
    padding: 30% 0%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    min-height: 300px;
}

.app-protocols-actions .circle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 58px;
    font-size: 36px;
    border: 1px solid #9d9d9d;
    border-radius: 50%;
    color: var(--color-white);
    background-color: var(--color-secondary);
}

.app-protocols-actions .square {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 58px;
    font-size: 36px;
    border: 1px solid #9d9d9d;
    border-radius: 5px;
    color: #fff;
    background: #ff0000;
}

.app-protocols-list {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5px;
    border: 0.5px solid var(--color-gray);
    border-radius: 5px;
    background-color: var(--color-white);
    max-height: 250px;
    overflow-y: auto;
}

.app-protocols-form-container::-webkit-scrollbar,
.app-protocols-list::-webkit-scrollbar {
    display: block;
    max-width: 0.5em;
    overflow: auto;
    max-height: 0.5em;
    padding: 10px;
}

.app-protocols-form-container::-webkit-scrollbar-thumb,
.app-protocols-list::-webkit-scrollbar-thumb {
    display: block;
    max-width: 0.5em;
    overflow: auto;
    max-height: 1em;
    background: #00000025;
    border-radius: 5px;
}

.app-protocols-list .app-protocols-list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1px auto;
    text-align: left;
    padding: 5px;
    width: 100%;
    min-height: 32px;
    border-radius: 5px;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    color: var(--color-gray-dark);
}

.app-protocols-list-item div:nth-child(2) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.app-protocols-list-item.selected,
.app-protocols-list-item:hover,
.app-protocols-list-item:hover i.bi,
.app-protocols-list-item.selected i.bi {
    background: #25cec5;
    color: var(--color-white);
}

i.bi-check-circle-fill {
    color: var(--color-secondary);
}

.app-protocols-footer {
    display: flex;
    margin: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.app-protocols-footer .app-button {
    display: block;
    margin: auto 5px;
    text-align: center;
    width: auto;
    min-width: 320px;
    min-height: 53px;
    max-height: 53px;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 18px !important;
    line-height: 34px;
    letter-spacing: -0.33px;
    white-space: nowrap;
    color: var(--color-white);
    border: hidden;
    box-shadow: -4px -4px 4px #ffffff, 2px 2px 4px #606060;
    border-radius: 5px;
}

.app-protocols-footer .app-button.default {
    background: none;
    font-family: "Noto Sans Regular" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    color: var(--color-gray-dark) !important;
}
