.app-dates {
    display: block;
    margin: auto;
    margin-top: 10%;
}

.app-dates-container {
    display: block;
    background-color: var(--color-white);
    padding: 10px 50px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
}

.app-dates-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    color: var(--color-secondary);
    font-weight: 700;
    font-size: 22px;
    font-family: 'Noto Sans Bold';
    line-height: 29.96px;
}

.app-dates-container fieldset {
    position: relative;
    border: 2px solid #39711F;
    border-radius: 5px;
    box-sizing: border-box;
}

.app-dates-container fieldset legend {
    display: flex;
    background-color: var(--color-white);
    color: var(--color-secondary-dark);
    flex-direction: row;
    font-weight: 700;
    font-size: 22px;
    justify-content: flex-start;
    height: fit-content;
    font-family: 'Noto Sans Bold';
    left: 20px;
    line-height: 29.96px;
    position: relative;
    padding: 0% 10px;
    top: -20px;
    width: fit-content;
    border-radius: 5px 5px 0px 0px;
    box-sizing: border-box;
}


.app-dates .btn {
    font-family: "Noto Sans Bold";
    font-weight: 700 !important;
    letter-spacing: 0.25px;
    padding-left: 25px;
    padding-right: 25px;
    margin: 0px 5px;
}

.app-dates .btn.btn-success {
    background: #64C736 !important;
    border: 1px solid #64C736 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.app-dates .btn.btn-outline-success {
    color: var(--color-secondary);
    border: 1px solid #64C736 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.app-dates .btn.btn-outline-success:hover {
    color: var(--color-white) !important;
    background: #64C736 !important;
    border: 1px solid #64C736 !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
