.app-forms-fields-options {
    display: flex;
    flex-direction: nowrap;
    justify-content: space-between;
    align-items: center;
    min-height: 68px;
    max-height: 68px;
    gap: 10px;
}

.app-forms-fields-options div:last-child {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-self: flex-end;
    width: fit-content;
}

.app-forms-toolbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 20px;
    gap: 10px;
    width: 100%;
}

.app-forms-toolbar-button {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    min-width: 240px;
    height: 48px;
    font-family: "Noto Sans Regular" !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 22px !important;
    line-height: 34px !important;
    letter-spacing: -0.33px;
    border-radius: 4px;
    color: var(--color-white) !important;
    background: #64c736 !important;
    box-shadow: 0px 4px 4px #00000075;
    border: hidden !important;
}

.app-forms-toolbar-button:disabled {
    border: 1px solid #ffffff !important;
    color: var(--color-white) !important;
    background: #c2c2c2 !important;
    box-shadow: 0px 4px 4px #00000075 !important;
}

.app-forms-toolbar-button.outline {
    border: 1px solid #39711f !important;
    color: var(--color-secondary-dark) !important;
    background: #ffffff !important;
    box-shadow: 0px 4px 4px #00000075 !important;
}

.app-forms-preview-title {
    position: relative;
    top: 0%;
    bottom: 0%;
    left: 0%;
    bottom: 0%;
    display: flex;
    background-color: var(--color-secondary);
    min-height: 38px;
    padding: 20px;
    border-radius: 5px 5px 0px 0px;
}

.app-forms-preview-title span {
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    color: var(--color-white);
}

.app-forms-preview-close-button {
    position: absolute;
    right: 1%;
    top: 25%;
    bottom: 25%;
    display: flex;
}

.app-forms-preview-close-button button {
    display: flex;
    border: 1px solid #ffffff;
    border-radius: 50%;
    background-color: var(--color-secondary);
    width: 32px;
    height: 32px;
}

.app-forms-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    font-family: "Noto Sans Medium" !important;
    font-style: normal !important;
    border-bottom: 1px solid var(--color-gray);
}

.app-forms-tabs .app-forms-tabs-item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    font-weight: 400 !important;
    padding: 5px;
    border: 1px solid var(--color-gray);
    border-radius: 5px 5px 0px 0px;
}

.app-forms-tabs .app-forms-tabs-item.active {
    font-weight: 700 !important;
    color: var(--color-secondary);
    background: var(--color-gray)50;
}

.app-forms-sections-container,
.app-forms-fields-container,
.app-forms-preview-container {
    position: relative;
    display: block;
    height: auto;
    padding-right: 10px;
}

.app-forms-sections-container,
.app-forms-fields-container {
    margin: 10px 0px;
    padding: 10px;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    background: var(--color-gray);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.app-forms-fields-item {
    display: block;
    padding: 10px 10px;
    border: 1px solid var(--color-gray);
    margin-bottom: 10px;
    border-radius: 10px 10px 10px 10px;
    box-shadow: 0px 2px 2px #00000075;
}

.app-forms-fields-list {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
}

.app-forms-fields-list .form-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 99.5px;
    max-height: 99.5px;
}

.app-form-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 99px;
    max-height: 99px;
}

.app-form-button-delete {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans Bold";
    width: auto;
    height: 38px;
    padding: 10px;
    color: var(--color-white) !important;
    background: #ff0000 !important;
    border: hidden !important;
    border-radius: 4px !important;
    box-shadow: 0px 4px 8px #00000075 !important;
}

.app-forms-fields-item::-webkit-scrollbar,
.app-forms-sections-container::-webkit-scrollbar,
.app-forms-fields-container::-webkit-scrollbar,
.app-forms-preview-container::-webkit-scrollbar {
    display: block;
    max-width: 0.5em;
    overflow: auto;
    max-height: 0.5em;
}

.app-forms-fields-item::-webkit-scrollbar-thumb,
.app-forms-sections-container::-webkit-scrollbar-thumb,
.app-forms-fields-container::-webkit-scrollbar-thumb,
.app-forms-preview-container::-webkit-scrollbar-thumb {
    display: block;
    padding: 50px;
    max-width: 0.5em;
    overflow: auto;
    max-height: 1em;
    background: #00000025;
    border-radius: 5px;
}
