.app-calendar {
    display: grid;
    grid-template-columns: 4fr 8fr;
    gap: 10px;
    margin: 25px 0px 10px;
}

.app-calendar-options {
    display: block;
    display: grid;
    grid-template-rows: 2fr 10fr;
    gap: 10px;
}

.app-calendar-options-items {
    display: block;
    padding: 25px;
    border: 1px solid var(--color-gray);
    border-radius: 10px;
    background-color: var(--color-white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.app-calendar-options-items .list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 5px;
    white-space: nowrap;
    color: var(--color-secondary);
}

.app-calendar-options-items .list .title {
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.18px;
}

.app-calendar-options-items .list .subtitle {
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.18px;
}

.app-calendar-options-items .list .counter {
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
}

.app-calendar-options-items:nth-of-type(2) {
    display: grid;
    grid-template-rows: 6fr 6fr;
}

.app-calendar-options-items .list-status {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.app-calendar-options-items .list-status .status {
    display: grid;
    grid-template-columns: 2fr 10fr;
    gap: 5px;
    height: 35px;
}

.app-calendar-options-items .list-status .status .circle {
    display: flex;
    justify-content: flex-start;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin: 7.5px;
}

.app-calendar-options-items .status .circle.one {
    background-color: var(--color-secondary);
}

.app-calendar-options-items .status .circle.two {
    background: #FBDB33;
}

.app-calendar-options-items .status .circle.three {
    background: #ff0000cc;
}

.app-calendar-options-items .status .circle.four {
    background: #60606080;
}

.app-calendar-options-items .list-status .status .label {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
}

.app-calendar-container {
    display: block;
    padding: 25px;
    border: 1px solid var(--color-gray);
    border-radius: 10px;
    background-color: var(--color-white);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.app-calendar .modal-content {
    border: 0px solid #ffffff !important;
}

.app-calendar-modal-header {
    display: flex;
    flex-direction: row;
    align-content: space-between;
    justify-content: space-between;
    background-color: var(--color-secondary);
    color: var(--color-white) !important;
    border-radius: 4px 4px 0px 0px !important;
    padding: 10px 10px 5px 15px;
}

.app-calendar-modal-header .btn {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1px solid #ffffff !important;
    border-radius: 50% !important;
    color: var(--color-white) !important;
    width: 32px !important;
    height: 32px !important;
}

.app-calendar .modal-body .btn {
    display: block;
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.18px;
    background: #64C736 !important;
    color: var(--color-white);
    min-width: 156px;
}

.app-calendar .modal-body .btn:hover {
    display: block;
    background: #64C736 !important;
    color: var(--color-white);
    opacity: 0.9;
}

.app-calendar .modal-body .btn.btn-outline {
    display: block !important;
    color: #646464 !important;
    background: #ffffff !important;
}

.app-calendar .modal-body .btn.btn-outline:hover {
    display: block !important;
    color: var(--color-white) !important;
    background: #646464 !important;
    opacity: 0.9;
}
