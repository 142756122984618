.app-medicalcare {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    text-align: center;
    width: auto;
    height: auto;
    background: rgba(237, 240, 235, 0.5);
    border: 1px solid #ffffff;
    box-shadow: 4px -4px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.app-medicalcare-container {
    position: relative;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    padding: 10px;
}

.app-medicalcare-container form {
    position: relative;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    padding: 15px;
    background-color: var(--color-white);
    min-height: 600px;
    width: 100%;
    box-shadow: 4px -4px 4px rgba(0, 0, 0, 0.05), 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.app-medicalcare-title {
    display: flex;
    font-family: "Noto Sans Light";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-primary);
}

.app-medicalcare-title .italic {
    display: block;
    margin: 2px 5px;
    font-size: 18px;
    text-align: left;
    padding-left: 2px;
}

.app-form-group {
    display: grid;
    grid-template-columns: 3fr 5fr;
    grid-auto-rows: auto;
    margin-bottom: 1rem;
}

.app-form-label {
    display: flex;
    margin: auto 10px auto 5px;
    text-align: left;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: var(--color-gray-dark);
    width: 100%;
    white-space: nowrap;
}

.app-form-group input,
.app-form-group select {
    display: block;
    margin: auto;
    padding: 5px;
    width: 100%;
    height: 45px;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-gray-dark);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
    border-radius: 4px;
    border: 1px solid #00000025;
}

.app-form-group input::placeholder,
.app-form-group select::placeholder {
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: rgba(96, 96, 96, 0.5);
}


.app-medicalcare-button-group {
    position: relative;
    left: 0%;
    right: 0%;
    bottom: 0%;
    top: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.app-medicalcare-button {
    display: block !important;
    margin: auto;
    width: 250px;
    height: 48px;
    font-family: "Noto Sans Bold" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 34px !important;
    letter-spacing: -0.33px !important;
    background: #25cec5 !important;
    border-radius: 5px;
    color: var(--color-white) !important;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25) !important;
}

.app-medicalcare-button:hover,
.app-medicalcare-button:focus {
    opacity: 0.9;
}

.app-form-button .small-x {
    position: relative;
    top: 50%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    display: block;
    text-align: center;
    width: 250px;
    height: 48px;
    font-family: "Noto Sans Bold" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 14px !important;
    line-height: 34px !important;
    letter-spacing: 0.33px !important;
    color: var(--color-white) !important;
    background: #9b9b9b !important;
    border: hidden;
    border-radius: 6px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25) !important;
}

.app-divider {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 22px !important;
    max-width: 22px !important;
    height: auto;
    top: 0%;
    bottom: 0%;
    background-color: var(--color-secondary);
    border-radius: 0px 10px 10px 0px;
    justify-content: center;
    z-index: 1;
}

.app-divider .app-divider-container {
    position: sticky;
    top: 0%;
    bottom: 0%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: space-evenly;
    align-items: center;
    padding: 3.5rem 0px;
    max-width: 22px;
}


.app-divider .app-divider-container .label {
    display: block;
    font-family: "Noto Sans Italic";
    font-style: italic;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.18px;
    text-wrap: nowrap;
    transform: rotate(-90deg);
    color: var(--color-white);
}

.app-medicalcare-form-advanced {
    position: relative;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    height: auto;
    width: 99% !important;
    display: block;
    padding: 3rem 0rem 3rem 3rem;
    z-index: 0;
}

.app-medicalcare-photo {
    position: relative;
    top: -10% !important;
    right: -10% !important;
    bottom: 0% !important;
    left: 0% !important;
    display: block !important;
    margin: auto !important;
    text-align: center !important;
    width: fit-content;
    height: fit-content;
    border: 1px solid #c3c3c3;
    border-radius: 50%;
}

.app-medicalcare-photo img {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    align-content: center !important;
    border-radius: 50% !important;
    margin: auto;
    width: 110px !important;
    height: 110px !important;
}

.app-medicalcare-photo button {
    position: absolute !important;
    right: 5% !important;
    bottom: -5% !important;
    width: 28px !important;
    height: 28px !important;
    border-radius: 60% !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    background: #ffffff !important;
    border: 1px solid #c3c3c325 !important;
}

.app-form-error {
    position: relative;
    display: block !important;
    margin: 0px 0px 10px;
}

.app-form-error>span {
    position: relative;
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}


/* Modal */

.modal-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    color: var(--color-primary);
    border-bottom: hidden !important;
    margin: 0% 2%;
}

.modal-header .btn-close {
    border: 2px solid #606060 !important;
    border-radius: 50% !important;
    background-image: url('../../assets/icons/close-fill.svg') !important;
}

.modal-paragraph {
    display: block;
    text-align: center;
    margin: auto;
    font-family: "Noto Sans Light";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
}

.modal-body-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 2% 2%;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
}

.modal-body-header button {
    min-height: 45px;
    min-width: 232px;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.33px;
    color: var(--color-white);
    background: #25cec5;
    border: 1px solid #25cec5;
    border-radius: 4px;
}

.modal-body-header .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.33px;
    border-radius: 4px;
}

.modal-body-table-header div {
    display: block;
    text-align: left;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    white-space: nowrap;
    padding: 0px 4px;
}

.modal-body-table-content {
    background-color: var(--color-white);
    white-space: nowrap;
    max-height: 420px;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.modal-body-table-content .flex-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0% auto;
    padding: 10px;
}

.modal-body-table-content>.flex-row:hover,
.modal-body-table-content>.flex-row.selected {
    background: #64c73620;
    border-radius: 15px 10px;
}

.modal-body-table-content .flex-row div:first-child {
    text-align: left;
}

.modal-body-table-content .btn {
    display: flex;
    justify-content: center;
    align-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: -0.33px;
    color: var(--color-secondary-dark);
    border: 1px solid #39711f;
    border-radius: 5px;
}

.modal-body-table-content .btn:hover {
    background: #39711f;
    color: var(--color-white);
    opacity: 0.9;
}

.modal-body-table-content .flex-row .link {
    display: block;
    text-align: center;
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    /* identical to box height */
    text-decoration-line: underline;
    color: var(--color-primary);
    margin: 5% auto;
}

.modal-avatar {
    border-radius: 50%;
    width: 128px;
    height: 128px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal-group {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin-top: 1%;
    margin-left: 2%;
    justify-content: space-between;
}

.modal-label {
    display: block;
    margin: auto 1%;
    text-align: right;
    width: calc(100% / 2);
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
}

.modal-select {
    display: block;
    margin: auto;
    padding: 0px 10px;
    font-family: "Noto Sans Bold" !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 22px;
    line-height: 30px;
    color: var(--color-gray-dark);
    height: 45px;
    width: 100%;
    border: 0.5px solid #00000025;
    border-radius: 4px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}

.modal-body-table-content::-webkit-scrollbar {
    display: block;
    max-width: 0.5em;
    overflow: auto;
    max-height: 0.5em;
    padding: 10px;
}

.modal-body-table-content::-webkit-scrollbar-thumb {
    display: block;
    max-width: 0.5em;
    overflow: auto;
    max-height: 1em;
    background: #00000025;
    border-radius: 5px;
}

.modal-select:focus-visible {
    outline: none;
}

.modal-button {
    min-width: 310px;
    min-height: 48px;
    font-family: "Noto Sans Bold" !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 22px !important;
    line-height: 34px !important;
    letter-spacing: -0.33px !important;
    color: var(--color-white) !important;
    border: hidden;
    background: #25cec5 !important;
    box-shadow: 0px -4px 8px #ffffff75, 0px 4px 8px #00000075;
    border-radius: 6px;
}

.modal-button:disabled {
    opacity: 0.9;
    background: #606060 !important;
    border: none !important;
}

.modal-button-outline {
    min-width: 310px;
    min-height: 48px;
    font-family: "Noto Sans Medium" !important;
    font-style: normal;
    font-weight: 500 !important;
    font-size: 22px !important;
    line-height: 34px !important;
    letter-spacing: -0.33px !important;
    color: var(--color-primary) !important;
    background: #ffffff !important;
    border: 2px solid #25cec5 !important;
    border-radius: 6px !important;
}

.modal-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 5%;
}

.modal-actions .btn:hover {
    opacity: 0.9 !important;
}

.modal-body .btn.btn-danger {
    box-shadow: 0px -4px 8px #ffffff75, 0px 4px 8px #00000050 !important;
}

.modal-body .btn.btn-success-outline {
    color: var(--color-secondary-dark) !important;
    border: 1px solid #39711f !important;
    background: none !important;
    font-weight: 500 !important;
    border-radius: 5px !important;
    box-shadow: 0px -4px 8px #ffffff75, 0px 4px 8px #00000050 !important;
}

.modal-body .btn.btn-info {
    background: #25cec5 !important;
    color: var(--color-white) !important;
    font-weight: 500 !important;
    border: 2px solid #25cec5 !important;
    border-radius: 5px !important;
    box-shadow: 0px -4px 8px #ffffff75, 0px 4px 8px #00000050 !important;
}
