.app-login {
    position: absolute;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: grid;
    grid-template-columns: 4fr 8fr;
    grid-auto-rows: auto;
}

.app-login-container {
    margin: 0;
    padding: 10px;
}

.app-login-logo {
    display: flex;
    align-items: center;
    text-align: center;
    justify-items: center;
    padding: 5% 0% 5%;
}

.app-login-logo img {
    display: block;
    margin: auto;
    text-align: center;
}


/* forms */

.app-login-form {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    display: block;
    margin: auto;
    text-align: center;
    width: auto;
    min-width: 421px;
    max-width: 445px;
    height: auto;
}

.app-login-form-title {
    display: block;
    margin: auto;
    text-align: left;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: -0.64px;
    color: #003da5;
}

.app-login-form-subtitle {
    display: block;
    margin: auto;
    text-align: left;
    color: var(--color-gray-dark);
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
}

.app-login-form-subtitle span {
    font-family: "Noto Sans Bold";
    font-weight: 700;
    color: var(--color-primary);
}

.app-login .form-group {
    position: relative;
    top: 0%;
    right: 0%;
    left: 0%;
    bottom: 0%;
}

.app-login .form-group input {
    display: flex;
    text-align: left;
    margin: 5% auto;
    padding: 15px;
    width: 100%;
    min-width: 385px;
    max-width: 100%;
    height: 60px;
    border: 2px solid #606060;
    border-radius: 6px;
}

.app-login .form-control input::placeholder {
    display: flex;
    text-align: left;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -0.33px;
    color: var(--color-gray-dark);
    opacity: 0.6;
}

.app-login .form-group button {
    position: absolute;
    top: 0%;
    right: 0%;
    bottom: 0%;
    display: block;
    margin: auto;
    text-align: center;
    padding: 0% 2%;
    background: none;
    border: 0;
    width: auto;
    height: auto;
    z-index: 1;
}

.app-login .app-form-checkbox {
    position: relative;
    top: 0%;
    right: 0%;
    left: 0%;
    bottom: 0%;
    display: flex;
    margin: 5% auto;
    width: 100%;
    min-width: 385px;
    max-width: calc(100%);
    justify-content: space-between;
}

.app-login .app-form-checkbox input[type="checkbox"] {
    display: none;
}

.app-login .app-form-checkbox label {
    position: relative;
    padding-left: 1.3em;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: flex;
    text-align: left;
    width: 100%;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 19px;
    letter-spacing: -0.33px;
    color: var(--color-gray-dark);
}

.app-login .app-form-checkbox label::before {
    position: absolute;
    top: 0%;
    left: 0%;
    content: "";
    width: 19px;
    height: 19px;
    border: 1px solid #606060;
    border-radius: 2px;
}

.app-login .app-form-checkbox input[type="checkbox"]:checked+label::before {
    display: block;
    margin: auto;
    text-align: center;
    border: 1px solid #25cec5 !important;
    content: "\f00c";
    font-family: "Font Awesome 6 Free";
    color: white;
    background: #25cec5;
    font-size: 16px;
}

.app-login .app-form-recapcha {
    position: relative;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    height: 84px;
    border-radius: 4px;
    display: block;
    text-align: center;
    margin: 5% auto;
    width: 92%;
    background: #60606025;
}

.app-login .app-form-button {
    position: relative;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    width: 100%;
    min-width: 385px;
    height: 64px;
    min-height: 64px;
}

.app-login .app-form-button button {
    display: block;
    text-align: center;
    margin: auto;
    width: 100%;
    height: 100%;
    border: 1px solid #64c736;
    border-radius: 6px;
    background-color: var(--color-secondary);
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.33px;
    color: var(--color-white);
}

.app-login .app-form-footer {
    position: relative;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    height: auto;
    width: 100%;
}

.app-login .app-form-footer>span {
    display: block;
    margin: 5% auto;
    text-align: center;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.33px;
    color: var(--color-gray-dark);
}

.app-login .app-form-footer span.link {
    text-decoration-line: underline;
    color: #003da5;
}


/* footer */

.app-login .app-login-footer {
    position: absolute;
    right: 0%;
    left: 0%;
    bottom: 10px;
    width: calc(32%);
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
}


.app-login .app-form-resend {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.app-login .app-form-resend button {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
    justify-content: space-between;
    align-items: center;
    font-family: "Noto Sans Regular";
    font-weight: 400;
    font-style: normal;
    margin: -10px 0px 10px;
    color: var(--color-gray-dark);
    border: hidden;
    background: none;
}

.app-login .app-form-resend button:disabled {
    opacity: 0.8;
}


/* background */

.app-login .app-login-background {
    position: relative;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    background: linear-gradient(to bottom, #dedcdd, #d1cdce);
    background-image: url("../../assets/images/login/background.svg");
    background-size: cover;
    background-position-x: -150px;
    background-repeat: no-repeat;
}
