.app-doctors-schedule {
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    margin: auto 0px;
}

.app-doctors-schedule-items {
    display: block;
    margin: 10px auto;
    padding: 10px 0px;
}

.app-doctors-schedule-items .schedule-title {
    font-family: 'Noto Sans Bold';
    font-size: 20px;
    font-weight: 700;
    color: var(--color-secondary);
    margin-bottom: 10px;
}

.app-doctors-schedule-items .form-label {
    font-family: 'Noto Sans Bold';
    font-size: 17px;
    font-weight: 700;
    color: var(--color-secondary);
}

.app-doctors-schedule-items .form-label.time {
    display: flex;
    align-self: center;
    vertical-align: super;
    font-size: 15px;
    font-weight: 700;
    padding-right: 5px;
}
