.app-patientcare-form {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    padding: 1% 3%;
    min-width: 1254px;
    background: #f5f7f4;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.app-patientcare-form-title {
    display: flex !important;
    font-family: "Noto Sans" !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 28px !important;
    line-height: 28px !important;
    letter-spacing: -0.18px !important;
    margin-bottom: 15px !important;
    color: var(--color-secondary);
}

.app-patientcare-form form .row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: auto;
}

.app-patientcare-form form .row div {
    display: block;
    margin: auto;
    text-align: left;
}

.app-patientcare-form form .form-label {
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 25px;
    /* identical to box height */
    color: #000000;
    white-space: nowrap;
}

.app-patientcare-form form .form-control-plaintext {
    display: block;
    margin: -10px auto 15px;
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    color: var(--color-gray-dark);
}

.app-patientcare-form-avatar {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    height: fit-content !important;
    margin-left: -125px !important;
    box-shadow: 0px, 8px, 8px, #000000 !important;
}

.app-patientcare-form-avatar .avatar {
    display: block;
    margin: auto;
    text-align: center;
    width: 192px !important;
    height: 192px !important;
    border-radius: 50% !important;
    box-shadow: 0px, 8px, 8px, #000000 !important;
}

.app-button-outline {
    margin: 5px auto;
    min-width: 223px;
    min-height: 34px;
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.33px;
    color: var(--color-secondary-dark);
    background-color: var(--color-white);
    border: 2px solid #39711f;
    border-radius: 6px;
}

.app-patientcare-steps-container {
    display: block;
    margin: auto;
    text-align: center;
    padding: 1% 2% 1%;
    background: var(--color-gray);
    border-radius: 10px;
}


.app-patientcare-steps-title {
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.18px;
}

.app-patientcare-steps-container.status_cyan .app-patientcare-steps-title,
.app-patientcare-steps-container.status_cyan .app-patientcare-step.active {
    color: var(--color-primary);
}

.app-patientcare-steps-container.status_green .app-patientcare-steps-title,
.app-patientcare-steps-container.status_green .app-patientcare-step.active {
    color: var(--color-secondary);
}

.app-patientcare-steps-container.status_red .app-patientcare-steps-title,
.app-patientcare-steps-container.status_red .app-patientcare-step.active {
    color: var(--color-danger);
}

.app-patientcare-steps-subtitle {
    display: block;
    text-align: left;
    margin: 15px auto 10px;
    padding-left: 10px;
    align-items: center;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
}

.app-patientcare-steps {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.app-patientcare-steps-icons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
}

.app-patientcare-steps-bar {
    position: absolute;
    top: 10%;
    left: 5%;
    right: 5%;
    width: auto;
    height: 15px;
    background: #f0f0f0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
}

.app-patientcare-steps-bar span {
    display: block;
    width: 0%;
    min-width: 0%;
    max-width: 100%;
    height: 15px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    transition: 0.3s;
}

.app-patientcare-steps-container.status_cyan .app-patientcare-steps-bar span {
    background: #25cec5;
}

.app-patientcare-steps-container.status_green .app-patientcare-steps-bar span {
    background-color: var(--color-secondary);
}

.app-patientcare-steps-container.status_red .app-patientcare-steps-bar span {
    background: #ff0000;
}

.app-patientcare-step .circle {
    position: relative;
    left: 0%;
    top: 0%;
    display: flex;
    margin: auto;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: #f0f0f0;
    border-radius: 50px;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.app-patientcare-step .circle button {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: auto;
    background-color: #bfbfbf;
    border: hidden;
    border-radius: 50%;
    font-family: "Roboto Bold";
    font-weight: 700;
    font-size: 19.8538px;
    letter-spacing: 0.1em;
    color: var(--color-white);
    width: calc(75%);
    height: calc(75%);
}

.app-patientcare-step .icon {
    display: block;
    width: 12px;
    height: 10px;
    margin: 5px auto;
    color: #878787;
}

.app-patientcare-step .dialog {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    display: flex;
    margin: 5px;
    padding: 2px 5px;
    justify-content: center;
    align-items: center;
    width: 110px;
    height: 46px;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16.34px;
    letter-spacing: 0.01em;
    background: #f0f0f0;
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 50px;
    min-height: 100%;
}

.dialog span {
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.app-patientcare-steps-container.status_cyan .app-patientcare-step.active .circle button,
.app-patientcare-steps-container.status_cyan .app-patientcare-step.active .dialog {
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    background: #25cec5;
    color: var(--color-white);
}


.app-patientcare-steps-container.status_cyan .app-patientcare-step.active .icon i {
    color: var(--color-primary);
}

.app-patientcare-steps-container.status_green .app-patientcare-step.active .circle button,
.app-patientcare-steps-container.status_green .app-patientcare-step.active .dialog {
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    background-color: var(--color-secondary);
    color: var(--color-white);
}

.app-patientcare-steps-container.status_green .app-patientcare-step.active .icon i {
    color: var(--color-secondary);
}


.app-patientcare-steps-container.status_red .app-patientcare-step.active .circle button,
.app-patientcare-steps-container.status_red .app-patientcare-step.active .dialog {
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    background: #ff0000;
    color: var(--color-white);
}


.app-patientcare-steps-container.status_red .app-patientcare-step.active .icon i {
    color: var(--color-danger);
}
