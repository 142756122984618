.app-reports {
    position: relative;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    display: block;
    margin: 4% auto 2%;
    width: auto;
    min-width: calc(100%);
    max-width: calc(100%);
    min-height: 520px;
}

.app-reports fieldset {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    padding: 20px;
    border: 2px solid var(--color-japanese-laurel);
    border-radius: 5px;
}

.app-reports legend {
    position: absolute;
    top: -20px;
    left: 20px;
    font-family: 'Noto Sans Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    background-color: var(--color-white);
    width: fit-content;
    /* identical to box height */
    color: var(--color-secondary-dark);
}
