.not-found {
    display: flex;
    align-content: center;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background-image: url(../../assets/images/home/background.svg);
    background-size: cover;
}

.not-found span {
    display: block;
    margin: auto;
    text-align: center;
    font-size: 3.5rem;
    color: #c2c2c2;
    font-weight: bolder;
}


