/* header actions */

.app-container-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: nowrap;
}

.app-container-actions-label {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    font-family: "Noto Sans Regular";
    font-weight: 400;
    font-size: 1.5rem !important;
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
    white-space: nowrap;
}

.app-container-actions-button {
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
}


/* Buttons */

.app-button {
    display: block;
    margin: auto 5px;
    text-align: center;
    width: auto;
    min-width: 320px;
    min-height: 53px;
    max-height: 53px;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: -0.33px;
    white-space: nowrap;
    color: var(--color-white);
    border: hidden;
    background: #25cec5;
    box-shadow: -4px -4px 4px #ffffff, 2px 2px 4px #606060;
    border-radius: 5px;
}

.app-button.small {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    min-width: 196px;
    min-height: 34px;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: -0.33px;
    white-space: nowrap;
    text-wrap: nowrap;
    border: hidden;
    background: #25cec5;
    box-shadow: -4px -4px 4px #ffffff, 2px 2px 4px #606060;
    border-radius: 5px;
}

.app-button-outline.small {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    margin: auto;
    text-align: center;
    width: auto;
    min-width: 356px;
    min-height: 34px;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    letter-spacing: -0.33px;
    white-space: nowrap;
    color: var(--color-japanese-laurel);
    border: 2px solid var(--color-japanese-laurel);
    background-color: var(--color-white);
    box-shadow: -4px -4px 4px #ffffff, 2px 2px 4px #606060;
    border-radius: 5px;
}


/* Forms */

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
}

.app-form-title {
    display: flex;
    flex-direction: row;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: var(--color-japanese-laurel);
}


/* Boxshadow */

.boxshadow {
    margin: 62px auto;
    padding: 25px;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.boxshadow.white {
    background-color: var(--color-white);
    box-shadow: -4px -4px 8px rgba(255, 255, 255, 0.25), 0px 4px 8px rgba(0, 0, 0, 0.25);
}

.boxshadow-title {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    color: var(--color-japanese-laurel);
    white-space: nowrap;
}

.boxshadow-subtitle {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: var(--color-granite-gray);
    white-space: nowrap;
}

.boxshadow-container {
    display: block;
    height: auto;
    max-height: 250px;
    margin: 10px 0px;
    overflow-x: hidden;
    overflow-y: auto;
}

.boxshadow-container::-webkit-scrollbar {
    display: block;
    max-width: 0.5em;
    overflow: auto;
    max-height: 0.5em;
    padding: 10px;
    scroll-behavior: auto;
}

.boxshadow-container::-webkit-scrollbar-thumb {
    display: block;
    max-width: 0.5em;
    overflow: auto;
    max-height: 1em;
    background: #00000025;
    border-radius: 5px;
    scroll-behavior: auto;
}


/* Table */

.app-container-table {
    display: block;
    margin: 40px 0px;
    padding: 37px;
    background: #ffffff !important;
    border: 1px solid var(--color-gray);
    border-radius: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.app-container-table.default {
    margin: auto;
    padding: auto;
    background: none;
    border: none;
    border-radius: none;
    box-shadow: none;
}

.app-table-title {
    display: flex;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    color: var(--color-primary);
    width: max-content;
}

.app-container-table .nav-link {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    gap: 5px;
    color: var(--color-gray-dark);
}

.table {
    border: hidden;
    border-radius: 5px 5px 0px 0px !important;
}


/* Footer */

.app-footer-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-top: 10px;
}

.app-table-counter {
    position: relative;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 0%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    min-height: 80px;
    max-height: 80px;
    margin-top: -20px;
    padding-left: 10px;
}

.app-table-rows {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    min-height: 48px;
    max-height: 48px;
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
    gap: 5px;
}

.app-table-records {
    display: flex;
    flex-direction: row;
    font-family: "Noto Sans Medium";
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
}
