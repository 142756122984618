:root {
    --color-primary: #25cec5;
    --color-secondary: #64c736;
    --color-secondary-dark: #39711f;
    --color-gray: #acacac;
    --color-gray-dark: #606060;
    --color-danger: #dc3545;
    --color-white: white;
}

*,
::after,
::before {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;
    box-sizing: border-box;
}

body::-webkit-scrollbar {
    display: none;
}

body:has(.app-navbar-menu.active) {
    display: block;
    overflow: hidden;
    height: calc(100%);
}


.collpase {
    visibility: collapse !important;
}

.collapse.show {
    visibility: visible !important;
}

/* swalalert2 inicio*/

.swal2-popup {
    display: block;
    min-width: 553px;
    min-height: 250px;
}

.swal2-header {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    min-height: 48px !important;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px 5px 0px 0px;
    color: var(--color-white);
    background-color: var(--color-secondary);
}

.swal2-header .swal2-header-title {
    display: block;
    margin: 0% 2%;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 44px;
    text-align: center;
    color: var(--color-white);
}

.swal2-header-close {
    position: absolute;
    right: 0%;
    top: 0%;
    bottom: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.swal2-header-close button {
    height: 36px;
    width: 36px;
    border: 2px solid var(--color-white);
    color: var(--color-white);
    border-radius: 50%;
    padding: auto;
    content: "\f057";
    font-family: "Font Awesome 6 Free";
    background: unset;
    font-size: 16px;
}

.swal2-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 70px 0px 0px;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 38px;
    color: var(--color-secondary);
    white-space: nowrap;
    margin-top: 35px !important;
}

.swal2-html-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: var(--color-gray-dark);
}

.loading {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    width: auto;
    height: auto;
    overflow: hidden;
}

.loading .spin {
    width: 32px;
    height: 32px;
    border: 2px solid var(--color-primary);
    border-radius: 50%;
    border-right: 2px solid var(--color-gray-dark);
    transition: 0.3s;
    animation: spin 0.5s linear infinite;
}

.loading .label {
    color: var(--color-primary);
    font-family: "Noto Sans Medium";
    font-weight: 700;
    font-style: normal;
    font-size: 22px;
    white-space: nowrap;
}

.error {
    color: var(--color-danger) !important;
    font-family: "Noto Sans Medium" !important;
    font-weight: 700 !important;
    font-style: normal !important;
    font-size: 22px !important;
    overflow: hidden !important;
}

.swal2-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: auto;
    text-align: center;
}

.swal2-actions .swal2-confirm,
.swal2-actions .swal2-cancel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    text-align: center;
    width: auto;
    min-width: 196px;
    height: auto;
    max-height: 46px;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    letter-spacing: -0.33px;
}

.swal2-actions .swal2-confirm {
    color: var(--color-white) !important;
    border: hidden;
    background-color: var(--color-secondary) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.swal2-actions .swal2-confirm:hover,
.swal2-actions .swal2-confirm:focus,
.swal2-actions .swal2-confirm:focus-visible {
    outline: var(--color-secondary) !important;
}

.swal2-actions .swal2-cancel {
    color: var(--color-primary);
    background-color: var(--color-white) !important;
    border: 0.5px solid var(--color-primary) !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.text-left {
    display: flex;
    flex-direction: row;
    text-wrap: nowrap;
}

.float-right {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: flex-end;
}

/* swalalert2 fin */

.modal::-webkit-scrollbar {
    display: block;
    max-width: 0.5em;
    overflow: auto;
    max-height: 0.5em;
    padding: 10px;
}

.modal::-webkit-scrollbar-thumb {
    display: block;
    max-width: 0.5em;
    overflow: auto;
    max-height: 1em;
    background-color: var(--color-gray);
    border-radius: 5px;
}

.bold {
    font-family: "Noto Sans Bold";
    font-weight: 700;
    font-style: normal;
    margin: auto 2px;
}

.italic {
    font-family: "Noto Sans italic";
    font-style: italic;
    font-weight: 400;
}

.regular {
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
}

.bg-gray-asparagus {
    background-color: #48613c !important;
    color: var(--color-white);
}

.bg-cadet-blue {
    background-color: #66a09d !important;
    color: var(--color-white);
}

.bg-viridian-green {
    background-color: #009d95 !important;
    color: var(--color-white);
}

.bg-maximun-blue-green {
    background-color: var(--color-primary) !important;
    color: var(--color-white);
}

.bg-green-ryb {
    background-color: var(--color-secondary);
    color: var(--color-white);
}

.text-green-ryb {
    color: var(--color-secondary);
}

.bg-japanese-laurel {
    background-color: var(--color-secondary-dark) !important;
    color: var(--color-white);
}

.text-japanese-laurel {
    color: var(--color-secondary-dark);
}

.bg-cornflower-blue {
    background-color: #6a9dff !important;
    color: var(--color-white);
}

.bg-spanish-gray {
    background-color: #9d9d9d !important;
    color: var(--color-white);
}

@font-face {
    font-family: "Noto Sans Black";
    src: url("./app/assets/fonts/NotoSans/NotoSans-Black.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Black Italic";
    src: url("./app/assets/fonts/NotoSans/NotoSans-BlackItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Bold";
    src: url("./app/assets/fonts/NotoSans/NotoSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Bold Italic";
    src: url("./app/assets/fonts/NotoSans/NotoSans-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Extra Bold";
    src: url("./app/assets/fonts/NotoSans/NotoSans-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Extra Bold Italic";
    src: url("./app/assets/fonts/NotoSans/NotoSans-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Extra Light";
    src: url("./app/assets/fonts/NotoSans/NotoSans-ExtraLight.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Extra Light Italic";
    src: url("./app/assets/fonts/NotoSans/NotoSans-ExtraLightItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Italic";
    src: url("./app/assets/fonts/NotoSans/NotoSans-Italic.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Light";
    src: url("./app/assets/fonts/NotoSans/NotoSans-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Light Italic";
    src: url("./app/assets/fonts/NotoSans/NotoSans-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Medium";
    src: url("./app/assets/fonts/NotoSans/NotoSans-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Medium Italic";
    src: url("./app/assets/fonts/NotoSans/NotoSans-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Regular";
    src: url("./app/assets/fonts/NotoSans/NotoSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans SemiBold";
    src: url("./app/assets/fonts/NotoSans/NotoSans-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans SemiBold Italic";
    src: url("./app/assets/fonts/NotoSans/NotoSans-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans Thin";
    src: url("./app/assets/fonts/NotoSans/NotoSans-Thin.ttf") format("truetype");
}

@font-face {
    font-family: "Noto Sans ThinItalic";
    src: url("./app/assets/fonts/NotoSans/NotoSans-ThinItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto Bold";
    src: url("./app/assets/fonts/Roboto/roboto.bold.ttf") format("truetype");
}
