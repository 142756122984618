/* Camera */

.app-camera {
    position: relative;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
}

.app-camera video {
    border-radius: 5px;
    position: relative;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
}

.app-camera img {
    position: absolute;
    right: 0%;
    top: 0%;
    width: 20%;
    height: 20%;
    transition: 0.3s;
    animation: 0.3ms;
    border-radius: 0px 5px 0px 25px;
}

.app-camera img:hover {
    position: absolute;
    right: 0%;
    top: 0%;
    width: 90%;
    height: 90%;
    transition: 0.3s;
    animation: 0.3ms;
    box-shadow: 0px 2px 4px 4px#ffffff25, -2px 2px 4px 4px#ffffff25;
}

.app-camera-actions {
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    right: 0%;
    bottom: 0%;
    left: 0%;
    margin: 15px auto 0px;
    border: 1px solid var(--color-gray);
    border-radius: 0px 0px 5px 5px;
    background: var(--color-gray);
}

.app-camera-actions button {
    border-radius: 5px;
    border: hidden;
    background: none;
    color: var(--color-primary);
    height: 34px;
    box-shadow: inset 0px 0px 4px var(--color-gray), -2px -1px 4px #ffffff, 2px 1px 4px #00000050;
}

.app-camera-actions button:hover {
    background: #25cec5;
    color: var(--color-white);
    opacity: 0.9;
}

.app-camera-loading {
    position: absolute;
    top: 0%;
    right: 0%;
    bottom: 0%;
    left: 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    align-items: center;
    color: var(--color-primary);
    z-index: 1;
}

.app-camera-loading .spin {
    border: 4px solid #25cec5;
    border-radius: 50%;
    border-right: 4px solid #ffffff;
    width: 32px;
    height: 32px;
    transition: 0.3s;
    animation: spin 0.3s linear infinite;
}

.app-camera-actions .devices {
    display: flex;
    flex-direction: row;
    gap: 5px;
    color: var(--color-primary);
    padding: 5px 5px;
    border-radius: 4px;
    margin: 2px;
    box-shadow: inset 0px 0px 4px var(--color-gray), -2px -1px 4px #ffffff, 2px 1px 4px #00000050;
}

.app-camera-actions .devices:hover {
    background: #25cec5;
    color: var(--color-white);
    opacity: 0.9;
}

.app-camera-actions .devices select {
    background: hidden;
    border: none;
    border-radius: 4px;
    color: var(--color-primary);
    background: #ffffff25;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
