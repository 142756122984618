body {
    width: auto;
    height: auto;
    background-image: url("./assets/images/home/background.svg");
    background-size: contain;
}


/* Header */

.app-header {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    min-height: 63px;
    max-height: 63px;
    background-color: var(--color-secondary);
    z-index: 2;
}

.app-container-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 64px auto 2%;
    padding-top: 50px;
}

.app-container-header .left {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
}

.app-container-header .left>span:first-of-type {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 10px;
    padding: 0px 5px;
}

.app-container-header .left span:first-of-type span {
    display: flex;
    align-content: flex-start;
    justify-content: flex-start;
    margin: 5px;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-secondary);
}

.app-container-header span:last-child {
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
    margin: 5px 0px;
}

.app-container-header .left span:last-child {
    display: flex;
    justify-content: flex-start;
    padding: 0px 5px;
}

.app-container-header .right span:first-child {
    display: block;
    margin: auto;
    text-align: right;
    font-family: "Noto Sans Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
}

.app-container-header .right span:last-child {
    display: flex;
    justify-content: flex-end;
    padding: 0px 5px;
}


/* Container */

.app-container {
    position: relative;
    top: 0%;
    left: 0%;
    right: 0%;
    bottom: 15px;
    display: block;
    margin: auto;
    text-align: center;
    min-width: calc(65%);
    max-width: 1280px;
    padding: 2%;
}

.app-container::-webkit-scrollbar {
    display: none;
}

.app-title {
    display: block;
    margin: 5px auto;
    text-align: left;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-secondary);
}

.app-date {
    text-align: left;
    font-family: "Noto Sans Regular";
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    /* identical to box height, or 127% */
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
}


/* Menu Options */

.app-options-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    gap: 30px;
}

.app-option {
    display: block;
    width: auto;
    text-align: center;
    min-width: 300px;
    height: auto;
    min-height: 300px;
    border: 0.01px solid #acacac !important;
    box-shadow: -4px -4px 4px #acacac, 4px 4px 4px var(--color-gray-dark);
    border-radius: 6px;
}

.app-options-container::after {
    content: '';
    width: 100%;
}

.app-option img {
    display: block;
    margin: 10% auto;
    text-align: center;
}

.app-option span {
    font-size: 32px;
    line-height: 28px;
    text-align: center;
    letter-spacing: -0.18px;
    color: var(--color-white);
}


/* Footer */

.app-footer {
    position: absolute;
    left: 0%;
    bottom: 10px;
    right: 0%;
    display: block;
    text-align: center;
    margin: auto;
    font-family: "Century Gothic";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 28px;
    letter-spacing: -0.18px;
    color: var(--color-gray-dark);
    z-index: -1;
}
